import React, { Component, Fragment } from 'react'
import { ABanner, Go, AButton } from 'shared/components/common'
import { Container, Col, Row, Alert } from 'reactstrap'
import Formsy from 'formsy-react'

import telRosa from 'img/vectores/tel-rosa.svg'
import fbRosa from 'img/vectores/fb-rosa.svg'
import twRosa from 'img/vectores/tw-rosa.svg'
import igRosa from 'img/vectores/ig-rosa.svg'
import tarjeta from 'img/descargas.jpg'
import mailRosa from 'img/vectores/mail-rosa.svg'
import { SendToContactBody } from 'services/MailService/types'
import { ContactProps, ContactState, ContactModel } from './types'
import { ApiErrors } from 'core/services/ApiService/types'
import { MailService } from 'services'
import { InputFormsy, SelectFormsy, TextAreaFormsy } from 'shared/components/formsy'
import { GenericService } from 'core/services'
import { States } from 'store/states'
import { Cities } from 'store/cities'

const modelToHtml = (model: ContactModel) => {
  return `
    Comentarios del cliente:<br /><br />
    Nombre Completo: <br />${model.name}<br /><br />
    Correo electrónico: <br />${model.email}<br /><br />
    Teléfono: <br />${model.phone}<br /><br />
    Estado: <br />${model.state}<br /><br />
    Ciudad: <br />${model.city}<br /><br />
    Comentarios: <br />${model.comments}<br /><br />
  `
}

React.createElement('div')

export class ContactLayout extends Component<ContactProps, ContactState> {
  form: any
  citiesSelect: any
  state: ContactState = {
    states: [],
    cities: [],
    canSubmit: false,
    submitting: false
  }

  componentDidMount() {
    this.setState({ fetchingStates: true })
    GenericService.list<States[]>('states', { orderBy: 'name', where: 'status=1' })
      .then(states => {
        this.setState({ states, fetchingStates: false })
      })
      .catch(err => {
        const newState: any = { fetchingStates: false }
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.contactError = err.message
        } else {
          newState.contactError = 'No se han podido cargar los estados. Intente de nuevo más tarde.'
          console.error(err)
        }
        this.setState(newState)
      })
  }

  onSubmit = (model: ContactModel) => {
    const body: SendToContactBody = {
      subject: 'Contacto desde la página',
      html_content: modelToHtml(model)
    }
    this.setState({ submitting: true, contactError: '', contactSucceded: false })
    MailService.sendToContact(body)
      .then(_res => {
        this.form.reset()
        this.setState({ submitting: false, contactSucceded: true })
      })
      .catch(err => {
        const newState: any = { submitting: false }
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.contactError = err.message
        } else {
          newState.contactError = 'No se ha podido enviar su comentario. Intente de nuevo más tarde.'
          console.error(err)
        }
        this.setState(newState)
      })
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  handleStateChange = (id: string) => {
    if (id) {
      const state = parseInt(id, 10)
      this.citiesSelect.resetValue()
      this.setState({ fetchingCities: true, selectedState: state })
      GenericService.list<Cities[]>('cities', {
        specialJoin: '[county_id=county]county',
        joinType: 'inner',
        select: '*,county.name',
        where: `city.status=1,county.status=1,county.state_id=${id}`,
        orderBy: 'city.name'
      })
        .then(cities => {
          this.setState({ cities: [...cities], fetchingCities: false })
        })
        .catch(err => {
          const newState: any = { fetchingCities: false }
          if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
            newState.contactError = err.message
          } else {
            newState.contactError = 'No se han podido cargar las ciudades. Intente de nuevo más tarde.'
            console.error(err)
          }
          this.setState(newState)
        })
    }
  }

  handleToggleAlert = () => {
    this.setState({ contactError: '', contactSucceded: false })
  }
  render() {
    const { canSubmit, submitting, contactError, contactSucceded, states, selectedState, fetchingCities, cities } = this.state
    return (
      <div>
        <ABanner title="Contáctanos" titleClassName="big-white" titleSize={12} />
        <Container className="pad">
          <Row className="serv-cliente">
            <Col sm={{ size: 6, offset: 1 }}>
              <br />
              <br />
              <h2 className="big-blue">Servicio al cliente</h2>
              <p>Escríbenos después de tu viaje y déjanos tus comentarios. Antes de o durante el viaje por favor llámanos.</p>
              <Formsy
                ref={(node: any) => {
                  this.form = node
                }}
                onValidSubmit={this.onSubmit}
                onValid={this.onValidForm}
                onInvalid={this.onInvalidForm}
              >
                <InputFormsy
                  className="blueplace"
                  name="name"
                  label="Nombre completo:"
                  inputProps={{ placeholder: 'Escribe tu(s) nombre(s) y apellido(s)', disabled: submitting }}
                  validations={{ isSpecialWords: true }}
                  validationErrors={{ isSpecialWords: 'El texto introducido no es un nombre válido' }}
                  required
                />
                <br />
                <Row>
                  <Col sm={6}>
                    <SelectFormsy
                      name="state"
                      label="Estado"
                      selectProps={{
                        className: 'custom-select',
                        disabled: submitting
                      }}
                      onChange={this.handleStateChange}
                      value=""
                      required
                    >
                      <option value="">Selecciona tu estado</option>
                      {states.map(s => (
                        <option value={s.id} key={s.id}>
                          {s.name}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                  <Col sm={6}>
                    <SelectFormsy
                      ref={(node: any) => {
                        this.citiesSelect = node
                      }}
                      name="city"
                      label="Ciudad"
                      selectProps={{
                        className: 'custom-select',
                        disabled: submitting || !selectedState || fetchingCities
                      }}
                      value=""
                      required
                    >
                      <option value="">Selecciona tu ciudad</option>
                      {cities.map(c => (
                        <option value={c.id} key={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </SelectFormsy>
                  </Col>
                </Row>
                <br />
                <InputFormsy
                  className="blueplace"
                  name="email"
                  label="Email:"
                  inputProps={{ placeholder: 'Escribe tu correo electrónico', disabled: submitting }}
                  validations={{
                    isEmail: true
                  }}
                  validationErrors={{
                    isEmail: 'El texto introducido no es un correo electrónico válido'
                  }}
                  required
                />
                <br />
                <InputFormsy
                  className="blueplace"
                  name="phone"
                  label="Teléfono"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu teléfono',
                    disabled: submitting,
                    maxLength: 10
                  }}
                  validations={{
                    isNumeric: true,
                    minLength: 10
                  }}
                  validationErrors={{
                    isNumeric: 'El número introducido no es un teléfono válido',
                    minLength: 'El número introducido no tiene una longitud válida'
                  }}
                  required
                />
                <br />
                <TextAreaFormsy
                  className="blueplace"
                  name="comments"
                  label="Comentarios:"
                  labelClassName="bluep"
                  inputProps={{
                    rows: 5,
                    disabled: submitting
                  }}
                  required
                />
                <br />
                {submitting ? (
                  <Fragment>
                    <span>
                      <i className="fa fa-spin fa-spinner" /> Enviando comentarios...
                    </span>
                    <br />
                  </Fragment>
                ) : null}
                {contactError || contactSucceded ? (
                  <Fragment>
                    <Alert
                      color={contactError ? 'danger' : 'success'}
                      isOpen={Boolean(contactError || contactSucceded)}
                      toggle={this.handleToggleAlert}
                      className="d-inline-block"
                    >
                      {contactError || 'Sus comentarios fueron enviados con éxito'}
                    </Alert>
                    <br />
                  </Fragment>
                ) : null}
                <AButton variant="big" type="submit" disabled={!canSubmit}>
                  Envíar
                </AButton>
              </Formsy>
            </Col>
            <Col sm={4} className="contacto-nos">
              <br />
              <br />
              <br />
              <ul>
                <h5 className="big-blue-mon">Dirección</h5>
                <li>Av. Zaragoza 240 Nte. Col. Centro</li>
                <li>Los Mochis, Sinaloa.</li>
                <li>C.P. 81200</li>
              </ul>
              <ul>
                <li>
                  <img src={telRosa} />{' '}
                  <Go className="RefGoGrey" to="/centrales">
                    Terminales
                  </Go>
                </li>
                <a href="https://www.facebook.com/viajaABORDO/" target="blank">
                  <li>
                    <img src={fbRosa} /> @viajaABORDO
                  </li>
                </a>
                <a href="https://twitter.com/AbordoAll" target="blank">
                  <li>
                    <img src={twRosa} /> @AbordoAll
                  </li>
                </a>
                <a href="https://www.instagram.com/viaja.abordo/" target="blank">
                  <li>
                    <img src={igRosa} /> @all.Abordo
                  </li>
                </a>
              </ul>
              <div className="w-100 text-right">
                <Go to="/descarga">
                  <img src={tarjeta} className="img-responsive" />
                </Go>
              </div>
            </Col>
          </Row>
          <br />
          <hr className="divider" />
        </Container>

        <Container>
          <Row>
            <Col sm={6} xs={12}>
              <h1 className="big-pink">Ventas</h1>
              <p style={{ fontSize: '1.5em' }}>Si necesitas ayuda para reservar un boleto, llámanos o chatea con nosotros.</p>
            </Col>
            <Col sm={{ size: 4, offset: 1 }} xs={10} className="contacto-nos">
              <br />
              <ul>
                <li style={{ whiteSpace: 'pre' }}>
                  <img src={telRosa} />{' '}
                  <Go className="RefGoGrey" to="/centrales">
                    Terminales
                  </Go>
                </li>
                <li style={{ whiteSpace: 'pre' }}>
                  <img src={mailRosa} /> cotizaciones@ptxpaqueteria.com
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
      </div>
    )
  }
}

export default ContactLayout
