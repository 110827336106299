import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { ContactLayout } from 'modules/contact/pages'

type ContactoProps = PageProps

interface ContactoState {
  mounted: boolean
}

class Contacto extends Component<ContactoProps, ContactoState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ContactLayout />
        </AAnimate>
      </Layout>
    )
  }
}

export default Contacto
